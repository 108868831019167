export const ServiceUrl = {
  ADMISSION: {
    SETTINGS: "v1/settings",
    LIST_VARIABLE_SETTINGS: "v1/admin/settings/variables",
    UPDATE_VARIABLE_SETTINGS: "v1/admin/settings/variables.update",
    ADD_VARIABLE_SETTINGS: "v1/admin/settings/variables.add",

    LIST_SMS_TEMPLATES: "v1/admin/settings/smstemplates",
    UPDATE_SMS_TEMPLATES: "v1/admin/settings/smstemplates.update",
    ADD_SMS_TEMPLATES: "v1/admin/settings/smstemplates.add",
    DELETE_SMS_TEMPLATES: "v1/admin/settings/smstemplates.delete",

    LIST_MAIL_TEMPLATES: "v1/admin/settings/mailtemplates",
    UPDATE_MAIL_TEMPLATES: "v1/admin/settings/mailtemplates.update",
    ADD_MAIL_TEMPLATES: "v1/admin/settings/mailtemplates.add",
    DELETE_MAIL_TEMPLATES: "v1/admin/settings/mailtemplates.delete",

    LIST_PAGES: "v1/admin/settings/pages",
    UPDATE_PAGE: "v1/admin/settings/pages.update",
    VIEW_PAGE: "v1/admin/settings/pages.view",
    DELETE_PAGE: "v1/admin/settings/pages.delete",
    ADD_PAGE: "v1/admin/settings/pages.add",

    USER_PROFILE: "v1/admin/settings/myprofile",
    USER_CHANGE_PASSWORD: "v1/admin/settings/myprofile/changepassword",

    NEW_USER_LOGIN: "v1/admin/user.new",
    LIST_USERS_LOGIN: "v1/admin/users.list",
    USER_STATUS_CHANGE: "v1/admin/user.status_change",
    REMOVE_USER_LOGIN: "v1/admin/user.remove",
    UPDATE_USER_LOGIN: "v1/admin/user.update",
    PASS_USER_LOGIN: "v1/admin/user.pass",
    PHARMACY_PASS_USER_LOGIN: "v1/admin/user.pass_pharmacy",

    NEW_USER_PHARMACY_LOGIN: "v1/admin/user.pharmacy_new",
    LIST_USERS_PHARMACY_LOGIN: "v1/admin/users.list_pharmacy",
    USER_STATUS_PHARMACY_CHANGE: "v1/admin/user.status_change_pharmacy",
    REMOVE_USER_PHARMACY_LOGIN: "v1/admin/user.remove_pharmacy",
    UPDATE_USER_PHARMACY_LOGIN: "v1/admin/user.update_pharmacy",
  },
  SETTINGS: {
    SAVE_DEPARTMENT: "v1/admin/settings/departments/save",
    LIST_DEPARTMENT: "v1/admin/settings/departments/list",
    DELETE_DEPARTMENT: "v1/admin/settings/departments/delete",
    UPDATE_DEPARTMENT: "v1/admin/settings/departments/update",

    SAVE_USERROLE: "v1/admin/rbac/userrole/save",
    LIST_USERROLE: "v1/admin/rbac/userrole/list",
    DELETE_USERROLE: "v1/admin/rbac/userrole/delete",
    UPDATE_USERROLE: "v1/admin/rbac/userrole/update",
  },
  CASHIER: {
    GET_NEW_BILL_ID: "v1/admin/bill.get_new_id",
    SEARCH_PATIENT_BILLS_FOR_BILLING: "v1/admin/bill.search_for_new_billing",
    SAVE_OP_INVOICE: "v1/admin/bill.save_op",
    SAVE_INVOICE: "v1/admin/bill.save",
    GET_OP_INVOICE: "v1/admin/bill.get_op_bill",
    GET_CASH_BILLS: "v1/admin/bill.get_cash_bill",
    LIST_OP_INVOICES: "v1/admin/bill.list_by_date",
    LIST_OP_INVOICES_BYCATEGORY: "v1/admin/bill.list_by_date_category",
    LIST_OP_INVOICES_ADVANCE: "v1/admin/bill.list_by_date_advance",
    LIST_OP_INVOICES_BYCATEGORY_GROUP:
      "v1/admin/bill.list_by_date_category_group",
    LIST_LAB_INVOICES: "v1/admin/bill.list_lab_by_date",
    CATEGORY_GROUP_NEW: "v1/admin/bill.categorygroup.new",
    CATEGORY_GROUP_LISTALL: "v1/admin/bill.categorygroup.listall",
    CATEGORY_GROUP_REMOVE: "v1/admin/bill.categorygroup.remove",
    CATEGORY_GROUP_UPDATE: "v1/admin/bill.categorygroup.update",
    CATEGORY_NEW: "v1/admin/bill.category.new",
    CATEGORY_LISTALL: "v1/admin/bill.category.listall",

    CATEGORY_REMOVE: "v1/admin/bill.category.remove",
    CATEGORY_UPDATE: "v1/admin/bill.category.update",
    CANCEL_INVOICE: "v1/admin/bill.cancel",
    PAY_INVOICE: "v1/admin/bill.change_cash_lab_invoice",
    DELETE_ADVANCE: "v1/admin/bill.delete_advance",
    CANCELLED_LIST: "v1/admin/bill.cancelled_list",
    FIND_INVOICE: "v1/admin/bill.find",
    DISCHARGE_BILL_REQ: "v1/admin/bill.discharge_bill_request",
    SAVE_DISCHARGE_BILL: "v1/admin/bill.discharge_newbill",
    SAVE_BILL_GROUP: "v1/admin/bill.bill_group.new",
    LIST_BILL_GROUPS: "v1/admin/bill.bill_group.list",
    REMOVE_BILL_GROUP: "v1/admin/bill.bill_group.remove",
    UPDATE_BILL_GROUP: "v1/admin/bill.bill_group.update",
    LIST_PAYMENT_TYPES: "v1/admin/bill.payment_type",
    LIST_PATIENT_ADVANCE: "v1/admin/bill.list_by_patient_advance",
    GET_LABORATORY: "v1/admin/bill.list_by_patient_laboratory_fees",
  },
  PATIENTS: {
    PATIENT_ID: "v1/admin/patient.id",
    SEARCH_BY_ID: "v1/admin/patients.search_by_id",
    LIST_INVOICES_BY_PATIENT: "v1/admin/bill.list_by_patient",
    SAVE_PATIENT: "v1/admin/patient.new",
    LIST_PATIENTS_WITH_LIMIT: "v1/admin/patients.list",
    REMOVE_PATIENT: "v1/admin/patient.remove",
    UPDATE_PATIENT: "v1/admin/patient.update",
    SAVE_PATIENT_ADVANCE: "v1/admin/patient.save_advance",
    GET_PATIENT_ADVANCE: "v1/admin/patient.get_advance",
    LIST_ADVANCE: "v1/admin/bill.advance_list",
    LIST_OP_REPORTS: "v1/admin/patient.op_report_list",
  },
  PHARMACY: {
    LIST_BILL_WITH_LIMIT: "v1/admin/creditbill.list",
    PRODUCT_LIST: "v1/admin/pharmacy.product/list-products",
    TABLET_LIST: "v1/admin/pharmacy.product/tablet-products",
    BATCH_LIST: "v1/admin/pharmacy.product/list-products-batch",
    SAVE_PRODUCTS: "v1/admin/pharmacy.product/save-bill",
    SAVE_RETURN_PRODUCTS: "v1/admin/pharmacy.product/save-return-bill",
    GET_BILL_ID: "v1/admin/pharmacy.product/get-bill-no",
    GET_RETURN_BILL_ID: "v1/admin/pharmacy.product/get-return-bill-no",
    LIST_INVOICES: "v1/admin/pharmacy.product/list-invoice",
    LIST_RETURN_INVOICES: "v1/admin/pharmacy.product/list-return-invoice",
    LIST_PURCHASE_INVOICES: "v1/admin/pharmacy.product/list-purchase-invoice",
    LIST_INDENT_INVOICES: "v1/admin/pharmacy.product/list-indent-invoice",
    LIST_PRODUCT_REPORTS: "v1/admin/pharmacy.product/list-report",
    LIST_PRODUCT_STOCK_REPORTS: "v1/admin/pharmacy.product/list-stock-report",
    GET_INVOICE: "v1/admin/pharmacy.product/get-invoice",
    GET_INVOICE_INDENT: "v1/admin/pharmacy.product/get-invoice-indent",
    GET_RETURN_INVOICE: "v1/admin/pharmacy.product/get-return-invoice",
    GET_PURCHASE_INVOICE: "v1/admin/pharmacy.product/get-purchase-invoice",
    GET_PATIENT_ID: "v1/admin/pharmacy.product/get-patient-id",
    LIST_PATIENT_LIST: "v1/admin/pharmacy.product/get-patient-list",
    FIND_PATIENT: "v1/admin/pharmacy.report/find-patients",
    LIST_PATIENT_PRODUCT_REPORTS:
      "v1/admin/pharmacy.report/list-product-report",
    LIST_PATIENT_CONSOL_PRODUCT_REPORTS:
      "v1/admin/pharmacy.report/list-consol-product-report",
    SAVE_TYPE: "v1/admin/pharmacy.report/save-type",
    LIST_INDENT: "v1/admin/pharmacy.report/indent-list",
    REMOVE_INDENT_TYPE: "v1/admin/pharmacy.report/delete-type",
    UPDATE_INDENT_TYPE: "v1/admin/pharmacy.report/update-type",
    CANCEL_INVOICE: "v1/admin/pharmacy.report/cancel-invoice",
    CANCEL_PURCHASE_INVOICE: "v1/admin/pharmacy.report/cancel-purchase-invoice",
    LIST_SUPPLIER: "v1/admin/pharmacy.report/get-supplier",
  },
  LABORATORY: {
    TEST_LIST_WITH_GROUP: "v1/admin/laboratory.test_list_with_group",
    SAVE_NEW_TEST_REQUEST: "v1/admin/laboratory.save_request",
    TEST_LIST_BY_DATE: "v1/admin/laboratory.test_list_bydate",
    CONSOL_TEST_LIST_BY_DATE: "v1/admin/laboratory.test_consol_list_bydate",
    TEST_LIST_BY_CONDITION: "v1/admin/laboratory.test_list_bycond",
    SAVE_TEST_RESULT: "v1/admin/laboratory.save_result",
    SAVE_TEST_GROUP: "v1/admin/laboratory.save_group",
    LIST_TEST_GROUP: "v1/admin/laboratory.list_groups",
    REMOVE_TEST_GROUP: "v1/admin/laboratory.remove_group",
    UPDATE_TEST_GROUP: "v1/admin/laboratory.update_group",
    LIST_TEST_LIST: "v1/admin/laboratory.list_test",
    REMOVE_TEST_LIST: "v1/admin/laboratory.remove_test",
    SAVE_TEST_LIST: "v1/admin/laboratory.add_test",
    UPDATE_TEST_LIST: "v1/admin/laboratory.update_test",
    UPDATE_TEST_LIST_PRODUCTS: "v1/admin/laboratory.update_test_products",
    LIST_TEST_PROFILES: "v1/admin/laboratory.list_profiles",
    REMOVE_TEST_PROFILES: "v1/admin/laboratory.remove_profile",
    SAVE_TEST_PROFILES: "v1/admin/laboratory.save_profile",
    UPDATE_TEST_PROFILES: "v1/admin/laboratory.update_profile",
    UPDATE_TEST_PROFILES_PRODUCTS:
      "v1/admin/laboratory.update_profile_products",
    LIST_PROFILES_TEST: "v1/admin/laboratory.list_profile_test",
    LIST_PROFILES_PRODUCTS: "v1/admin/laboratory.list_profile_products",
    LIST_TEST_PRODUCTS: "v1/admin/laboratory.list_test_products",
    LIST_TEST_NAME: "v1/admin/laboratory.list_test_name",
    LIST_PROFILE_NAME: "v1/admin/laboratory.list_profile_name",
    SAVE_PACKAGE: "v1/admin/laboratory.new_package",
    LIST_PACKAGE: "v1/admin/laboratory.list_package",
    DELETE_PACKAGE: "v1/admin/laboratory.delete_package",
    LIST_PACKAGE_DATA: "v1/admin/laboratory.list_package_data",
    UPDATE_PACKAGE: "v1/admin/laboratory.update_data",
    GET_STOCK_ITEM: "v1/admin/laboratory.get_tock_item",
    GET_STOCK_TAX: "v1/admin/laboratory.get_stock_tax",
    GET_GROUP: "v1/admin/laboratory.get_group",
    LIST_STOCK_LIST: "v1/admin/laboratory.list_stocks",
    LIST_BATCH_LIST: "v1/admin/laboratory.list_batch",
    LIST_BATCH_PRODUCTS: "v1/admin/laboratory.list_bacth_products",
    SAVE_STORE_PRODUCT_LIST: "v1/admin/laboratory.add_store_products",
    SAVE_STORE_BATCH_LIST: "v1/admin/laboratory.add_store_batch_products",
    GET_STORE_PRODUCTS: "v1/admin/laboratory.get_products",
    UPDATE_STORE_LIST: "v1/admin/laboratory.update_store",
    UPDATE_BATCH_LIST: "v1/admin/laboratory.update_batch",
    REMOVE_STORE_LIST: "v1/admin/laboratory.remove_product",
    REMOVE_BATCH_LIST: "v1/admin/laboratory.remove_bacth",
    LIST_SUPPLIER_LIST: "v1/admin/laboratory.supplier_list",
    LIST_GROUP_LIST: "v1/admin/laboratory.group_list",
    SAVE_SUPPLIER_LIST: "v1/admin/laboratory.add_supplier",
    SAVE_GROUP_LIST: "v1/admin/laboratory.add_group",
    UPDATE_SUPPLIER_LIST: "v1/admin/laboratory.update_supplier",
    UPDATE_GROUP_LIST: "v1/admin/laboratory.update_group",
    LIST_SUPPLIER: "v1/admin/laboratory.get_supplier",
    GET_BILL_ID: "v1/admin/laboratory.get_billno",
    SAVE_STORE_PURCHASE: "v1/admin/laboratory.save_store_purchase",
    LIST_PURCHASE_REPORT: "v1/admin/laboratory.list_purchase",
    GET_PURCHASE_REPORT: "v1/admin/laboratory.get_purchase",
    LIST_PRODUCT_LIST: "v1/admin/laboratory.list_products",
    LIST_SALES_REPORT: "v1/admin/laboratory.list_product_reports",
    LIST_OVERALL_SALES_REPORT: "v1/admin/laboratory.list_overall_stock_reports",
    LIST_SALES_INVOICE: "v1/admin/laboratory.list_sales_reports",
    GET_SALES_INVOICE: "v1/admin/laboratory.get_reports",
    LIST_PATIENT_LIST: "v1/admin/laboratory.get_patient_list",
    GET_LAB_PATIENT_LIST: "v1/admin/laboratory.get_lab_patient_list",
    GET_PATIENT_SEARCH_LIST: "v1/admin/laboratory.get_patient_search_list",
    SAVE_SALES: "v1/admin/laboratory.save_sales",
    REMOVE_SUPPLIER: "v1/admin/laboratory.remove_supplier",
    REMOVE_GROUP: "v1/admin/laboratory.remove_group",
    FIND_PATIENT: "v1/admin/laboratory.find_patients",
    PATIENT_CONSOL_REPORTS: "v1/admin/laboratory.consolidate_report",
    LIST_PATIENT_CONSOL_LAB_REPORTS: "v1/admin/laboratory.list_consol_lab_bill",

    LIST_MICROLAB_LIST: "v1/admin/laboratory.list_microlab",
    REMOVE_MICROLAB_LIST: "v1/admin/laboratory.remove_microlab",
    SAVE_MICROLAB_LIST: "v1/admin/laboratory.add_microlab",
    UPDATE_MICROLAB_LIST: "v1/admin/laboratory.update_microlab",
  },
  RECEPTION: {
    TOKEN_NO: "v1/admin/reception.token_no",
    SAVE_TOKEN: "v1/admin/reception.save_token",
    LIST_TOKENS: "v1/admin/reception.list_tokens",
    LIST_PATIENT_TOKENS: "v1/admin/reception.list_patient_tokens",
    GET_TOKEN: "v1/admin/reception.get_token",
    GET_SYMPTOMS: "v1/admin/reception.get_symptoms",
    GET_ALLERGIC: "v1/admin/reception.get_allergic",
    SAVE_SYMPTOMS: "v1/admin/reception.save_symptoms",
    SAVE_ANTECENDENT: "v1/admin/reception.save_antecendent",
    SAVE_ALLERGIC: "v1/admin/reception.save_allergic",
    SAVE_PERSONAL: "v1/admin/reception.save_personal",
    SAVE_FAMILY: "v1/admin/reception.save_family",
    GET_PRESCRIPTON: "v1/admin/reception.get_prescription",
    SAVE_TOKEN_DETAILS: "v1/admin/reception.save_token_details",
    SAVE_PRESCRIPTION_DETAILS: "v1/admin/reception.save_prescription_details",
    LIST_SYMPTOMS: "v1/admin/reception.list_symptoms",
    LIST_ANTECENDENT: "v1/admin/reception.list_antecendent",
    LIST_ALLERGIC: "v1/admin/reception.list_allergic",
    LIST_PERSONAL: "v1/admin/reception.list_personal",
    LIST_FAMILY: "v1/admin/reception.list_family",
  },
  IP: {
    SAVE_FLOOR: "v1/admin/floors.new",
    LIST_FLOORS: "v1/admin/floors.list",
    REMOVE_FLOOR: "v1/admin/floors.remove",
    UPDATE_FLOOR: "v1/admin/floor.update",
    SAVE_WARD: "v1/admin/ward.new",
    LIST_WARDS: "v1/admin/wards.list",
    REMOVE_WARD: "v1/admin/ward.remove",
    UPDATE_WARD: "v1/admin/ward.update",
    SAVE_ROOM_TYPE: "v1/admin/rommtype.new",
    LIST_ROOM_TYPES: "v1/admin/rommtypes.list",
    REMOVE_ROOM_TYPE: "v1/admin/rommtype.remove",
    UPDATE_ROOM_TYPE: "v1/admin/rommtype.update",
    SAVE_ROOM: "v1/admin/room.new",
    LIST_ROOMS: "v1/admin/rooms.list",
    LIST_AVAILABLE_ROOMS: "v1/admin/rooms.available_list",
    REMOVE_ROOM: "v1/admin/room.remove",
    UPDATE_ROOM: "v1/admin/room.update",

    IP_PATIENT_LAST_OP: "v1/admin/ip.get_patient_op",
    IP_NO: "v1/admin/ip.no",
    NEW_ADMISSION: "v1/admin/ip.admission",
    LIST_ADMISSIONS: "v1/admin/ip.admission_list",
    SAVE_ROOM_CHANGE: "v1/admin/ip.room_change",
    SAVE_DISCHARGE: "v1/admin/ip.discharge",

    NEW_CASE_HISTORY: "v1/admin/ip.case_history_new",
    LIST_CASE_HISTORY: "v1/admin/ip.case_history_list",

    SAVE_MANUAL_SUMMARY: "v1/admin/discharge.manual_summary/save-data",
    LIST_MANUAL_SUMMARY: "v1/admin/discharge.manual_summary/list-data",
    REMOVE_MANUAL_SUMMARY: "v1/admin/discharge.manual_summary/remove-data",
    GET_MANUAL_SUMMARY: "v1/admin/discharge.manual_summary/get-data",
    UPDATE_MANUAL_SUMMARY: "v1/admin/discharge.manual_summary/update-data",
    PATIENTS_BILL_LIST: "v1/admin/ip.patient_bill_list",
  },
  DOCTORS: {
    LIST_ALL: "v1/admin/dept.list",
    NEW_DOCTOR: "v1/admin/dept.new",
    REMOVE_DOCTOR: "v1/admin/dept.remove",
    UPDATE_DOCTOR: "v1/admin/dept.update",
    NEW_CONSULTANT: "v1/admin/consultant.new",
    LIST_CONSULTANTS: "v1/admin/consultant.list",
    REMOVE_CONSULTANT: "v1/admin/consultant.remove",
    UPDATE_CONSULTANT: "v1/admin/consultant.update",
  },
  ACCOUNTS: {
    LIST_ACCOUNT_TYPES: "v1/admin/accounts.list_types",
    LIST_ACCOUNT_GROUPS: "v1/admin/accounts.list_group",
    SAVE_ACCOUNT_GROUP: "v1/admin/accounts.save_group",
    DELETE_ACCOUNT_GROUP: "v1/admin/accounts.delete_group",
    UPDATE_ACCOUNT_GROUP: "v1/admin/accounts.update_group",
    SAVE_ACCOUNT_LEDGER: "v1/admin/accounts.save_ledger",
    LIST_ACCOUNT_LEDGERS: "v1/admin/accounts.list_ledgers",
    REMOVE_ACCOUNT_LEDGER: "v1/admin/accounts.remove_ledgers",
    UPDATE_ACCOUNT_LEDGER: "v1/admin/accounts.update_ledger",
    ACCOUNT_LEDGER: "v1/admin/accounts.listaccount",
    SAVE_ACCOUNT_BANK: "v1/admin/accounts.save_bank",
    LIST_ACCOUNT_BANKS: "v1/admin/accounts.list_banks",
    REMOVE_ACCOUNT_BANKS: "v1/admin/accounts.remove_banks",
    UPDATE_ACCOUNT_BANK: "v1/admin/accounts.update_bank",
    SAVE_PAYMENT_OUT: "v1/admin/accounts.save_paymentout",
    LIST_PAYMENT_OUT: "v1/admin/accounts.list_payments",
    LIST_SINGLE_PAYMENT: "v1/admin/accounts.list_payment",
    LIST_DAY_BOOK: "v1/admin/accounts.day_book",
  },
  DOCTOR: {
    LIST_BILL_WITH_LIMIT: "v1/admin/creditbill.list",
    PRODUCT_LIST: "v1/admin/pharmacy.product/list-products",
  },
  HR: {
    SAVE_GRADE: "v1/admin/hr.save_grade",
    LIST_GRADES: "v1/admin/hr.list_gardes",
    REMOVE_GRADE: "v1/admin/hr.remove_grade",
    UPDATE_GRADE: "v1/admin/hr.update_grade",
    SAVE_BREAKUP_HEAD: "v1/admin/hr.save_breakuphead",
    LIST_BREAKUP_HEADS: "v1/admin/hr.list_breakuphead",
    REMOVE_BREAKUP_HEAD: "v1/admin/hr.remove_breakuphead",
    UPDATE_BREAKUP_HEAD: "v1/admin/hr.update_breakuphead",
    EMPLOYEE_NEW_CODE: "v1/admin/hr.employee_code",
    SAVE_EMPLOYEE: "v1/admin/hr.employee_save",
    LIST_EMPLOYEES: "v1/admin/hr.employees",
    REMOVE_EMPLOYEE: "v1/admin/hr.employee_remove",
    UPDATE_EMPLOYEE: "v1/admin/hr.employee_update",
    EMPLOYEE_BY_CODE: "v1/admin/hr.employee_by_code",
    SAVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_save",
    LIST_BIOMETRIC_DEVICES: "v1/admin/hr.device_list",
    REMOVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_remove",
    UPDATE_BIOMETRIC_DEVICE: "v1/admin/hr.device_update",
    SAVE_HOLIDAY: "v1/admin/hr.holiday_save",
    LIST_HOLIDAYS: "v1/admin/hr.holiday_list",
    REMOVE_HOLIDAY: "v1/admin/hr.holiday_remove",
    ATT_MONTH_LOG: "v1/admin/hr.att_month_log",
  },
  FIELD_SETTINGS: {
    NEW: "v1/field_settings/new",
    LIST: "v1/field_settings/all",
    REMOVE: "v1/field_settings/delete",
    UPDATE: "v1/field_settings/update",
  },
  SMS: {
    CHECK_BALANCE: "v1/admin/sms/balance",
  },
  USERS: {
    STAFFS: {
      NEW: "v1/users/staff/new",
      UPDATE: "v1/users/staff/update",
      UPDATE_PASSWORD: "v1/users/staff/update_pass",
      UPDATE_STATUS: "v1/users/staff/update_status",
      LIST_ALL: "v1/users/staffs",
      REMOVE: "v1/users/staffs/delete",
      VIEW_PASS: "v1/users/staffs/pass",
      SAVE_SUBJECT_ALLOCATION: "v1/users/staff/save_subject",
      LIST_ALLOCATED_SUBJECTS: "v1/users/staff/list_staff_subjects",
      UPDATE_ALLOCATED_SUBJECT_STATUS: "v1/users/staff/update_subject_status",
      REMOVE_ALLOCATED_SUBJECT: "v1/users/staff/delete_subject",
    },
    STAFF_POSITIONS: {
      NEW: "v1/users/staff/position/new",
      LIST_ALL: "v1/users/staffs/positions",
      REMOVE: "v1/users/staffs/position/delete",
      UPDATE: "v1/users/staff/position/update",
    },
  },
  SUBJECTS: {
    BY_COURSE: "v1/subjects.by_course",
    BY_EXAM: "v1/subjects.by_exam",
  },
  HR: {
    SAVE_GRADE: "v1/admin/hr.save_grade",
    LIST_GRADES: "v1/admin/hr.list_gardes",
    REMOVE_GRADE: "v1/admin/hr.remove_grade",
    UPDATE_GRADE: "v1/admin/hr.update_grade",
    SAVE_BREAKUP_HEAD: "v1/admin/hr.save_breakuphead",
    LIST_BREAKUP_HEADS: "v1/admin/hr.list_breakuphead",
    REMOVE_BREAKUP_HEAD: "v1/admin/hr.remove_breakuphead",
    UPDATE_BREAKUP_HEAD: "v1/admin/hr.update_breakuphead",
    EMPLOYEE_NEW_CODE: "v1/admin/hr.employee_code",
    SAVE_EMPLOYEE: "v1/admin/hr.employee_save",
    LIST_EMPLOYEES: "v1/admin/hr.employees",
    REMOVE_EMPLOYEE: "v1/admin/hr.employee_remove",
    CHANGE_EMPLOYEE_STATUS: "v1/admin/hr.status_chagne",
    UPDATE_EMPLOYEE: "v1/admin/hr.employee_update",
    UPDATE_EMPLOYEE_SALARY: "v1/admin/hr.employee_update_salary",
    EMPLOYEE_BY_CODE: "v1/admin/hr.employee_by_code",
    SAVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_save",
    LIST_BIOMETRIC_DEVICES: "v1/admin/hr.device_list",
    REMOVE_BIOMETRIC_DEVICE: "v1/admin/hr.device_remove",
    UPDATE_BIOMETRIC_DEVICE: "v1/admin/hr.device_update",
    LIST_LEAVE: "v1/admin/hr.leave_list",
    SAVE_HOLIDAY: "v1/admin/hr.holiday_save",
    LIST_HOLIDAYS: "v1/admin/hr.holiday_list",
    REMOVE_HOLIDAY: "v1/admin/hr.holiday_remove",
    SAVE_SUNDAYS: "v1/admin/hr.save_sundays",
    ATT_MONTH_LOG: "v1/admin/hr.att_month_log",
    SAVE_BRANCH: "v1/admin/hr.branch/save-data",
    LIST_BRANCHES: "v1/admin/hr.branch/list-data",
    REMOVE_BRANCHE: "v1/admin/hr.branch/remove-data",
    UPDATE_BRANCHE: "v1/admin/hr.branch/update-data",
    SAVE_DEPARTMENT: "v1/admin/hr.department/save-data",
    LIST_DEPARTMENTS: "v1/admin/hr.department/list-data",
    LIST_STDEPARTMENTS: "v1/admin/student_dept/list-data",
    REMOVE_DEPARTMENT: "v1/admin/hr.department/remove-data",
    UPDATE_DEPARTMENT: "v1/admin/hr.department/update-data",

    SAVE_TYPE: "v1/admin/hr.type/save-data",
    LIST_TYPE: "v1/admin/hr.type/list-data",
    REMOVE_TYPE: "v1/admin/hr.type/remove-data",
    UPDATE_TYPE: "v1/admin/hr.type/update-data",

    SAVE_LEAVE_TYPE: "v1/admin/hr.leavetype/save-data",
    LIST_LEAVE_TYPE: "v1/admin/hr.leavetype/list-data",
    REMOVE_LEAVE_TYPE: "v1/admin/hr.leavetype/remove-data",
    UPDATE_LEAVE_TYPE: "v1/admin/hr.leavetype/update-data",
    EMPLOYEE_LEAVE_LIST: "v1/admin/hr.leavetype/get-employee-allowedleaves",

    SAVE_DESIGNATION: "v1/admin/hr.designation/save-data",
    LIST_DESIGNATIONS: "v1/admin/hr.designation/list-data",
    REMOVE_DESIGNATION: "v1/admin/hr.designation/remove-data",
    UPDATE_DESIGNATION: "v1/admin/hr.designation/update-data",

    SAVE_SHIFT: "v1/admin/hr.shift_save",
    LIST_SHIFT: "v1/admin/hr.shift_list",
    UPDATE_SHIFT: "v1/admin/hr.shift_update",
    REMOVE_SHIFT: "v1/admin/hr.shift_remove",

    LAST_DEVICEID: "v1/admin/hr.last_inserted_deviceid",
    INSERT_DEVICE_DATAS: "v1/admin/hr.device_monthly_data",

    SAVE_ATTENDANCE: "v1/admin/hr.attendance/save-data",
    LIST_ATTENDANCE: "v1/admin/hr.attendance/list-data",
    REMOVE_ATTENDANCE: "v1/admin/hr.attendance/remove-data",
    UPDATE_ATTENDANCE: "v1/admin/hr.attendance/update-data",
  },
  PAYROLL: {
    SAVE_LEAVE: "v1/admin/payroll.save_leave",
    LIST_EMPLOYEES_LEAVES: "v1/admin/payroll.list_data",
    UPDATE_EMPLOYEES_LEAVE: "v1/admin/payroll.update_data",
    REMOVE_EMPLOYEES_LEAVE: "v1/admin/payroll.remove_data",

    SAVE_PERMISSION: "v1/admin/payroll.save_permission",
    LIST_EMPLOYEES_PERMISSION: "v1/admin/payroll.list_data_permission",
    UPDATE_EMPLOYEES_PERMISSION: "v1/admin/payroll.update_permission",
    REMOVE_EMPLOYEES_PERMISSION: "v1/admin/payroll.remove_permission",

    LIST_BREAKUP_HEADS: "v1/admin/payroll.list_breakup_heads",
    SAVE_HEAD: "v1/admin/payroll.save_head",
    UPDATE_HEAD: "v1/admin/payroll.update_head",
    REMOVE_HEAD: "v1/admin/payroll.remove_head",
    GET_BREAK_HEAD: "v1/admin/payroll.get_breakhead",

    SAVE_GRADE: "v1/admin/payroll.save_data_setup",
    LIST_SETUP: "v1/admin/payroll.list_data_setup",
    UPDATE_SETUP: "v1/admin/payroll.update_data_setup",
    REMOVE_SETUP: "v1/admin/payroll.remove_data_setup",

    SAVE_SALARY: "v1/admin/payroll.save_salary",
    LIST_SALARY: "v1/admin/payroll.salary_list",
    UPDATE_SALARY: "v1/admin/payroll.update_salary",
    LIST_SALARY_SETTINGS: "v1/admin/payroll.salary_settings_list",
    LIST_SALARY_DATA: "v1/admin/payroll.salary_list_data",

    LIST_EMPLOYEE_SALARY_DETAILS: "v1/admin/payroll.employee_salary_details",
    EMPLOYEE_SALARY_SETTINGS: "v1/admin/payroll.employee_salary_settings",

    SAVE_MONTH_SALARY: "v1/admin/payroll.save_employee_month_salary",
    LIST_BIOMETRIC_SUMMARY: "v1/admin/payroll.get_monthly_summary",
    LIST_DEVICELOG: "v1/admin/payroll.device_log_month",
    UPDATE_PAYROLL_ALLOWANCE: "v1/admin/payroll.payroll_allowance_update",
    EMPLOYEES_LEAVE_PERMISSION: "v1/admin/payroll.permission_leave_count",
    SAVE_RULES: "v1/admin/payroll.save_rules",
    LIST_RULES: "v1/admin/payroll.list_rules",
    PAYROLL_REPORTS: "v1/admin/payroll.payroll_reports",
    GET_LEAVE_DETAILS: "v1/admin/payroll.payroll_leave_reports",
    GET_SURRENDER_LEAVE: "v1/admin/payroll.get_surrender_leaves",
    SURRENDER_LEAVE: "v1/admin/payroll.employee_surrender_leaves",
    LEAVE_ALLOTMENT: "v1/admin/payroll.employee_leave_allotment",
    GET_CHECKUPLIST: "v1/admin/payroll.payroll_checkuplist",
    GET_BANKLIST: "v1/admin/payroll.payroll_banklist",
    GET_PFREPORT_LIST: "v1/admin/payroll.payroll_pfreport",
    GET_PAYSLIP: "v1/admin/payroll.payroll_salary_slip",
  },
};
